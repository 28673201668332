

.menu-button {
  background-color: transparent;
  color: rgb(75, 75, 75);
  border: none;
  border-radius: 4px;
  margin-right: 2px;
  padding: 2px 7px;
}

.menu-button:hover {
  background-color: rgb(202, 103, 194);
}
.menu-button:focus {
  background-color: rgb(202, 103, 194);
}

.menu-button:disabled {
  background-color: transparent;
  color: rgb(98, 98, 98);
}

.menu-button::after {
  display: none !important; 
}


.dropdown-menu {
  box-shadow: 0 0 5px 2px rgb(169, 69, 199);
}

.dropdown-item {
  font-size: 14px;
  padding: 1.5px 6px;
}



.green-button {
  color: green;
  background-color: rgb(224, 251, 241);
}

.blue-button {
  color: blue;
  background-color: rgb(225, 238, 249);
}


