
.refreshButton {
  float: right;
  padding: 0 6px 3px 6px;
}

.wordSuggestion {
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
}
.wordSuggestion:hover {
  background-color: #e9fcf3;
}
