
.cluesCard {
  margin-bottom: 5px;
  border-width: 1.5px;
  border-color: black;
}

.cluesCard .card-header {
  text-align: center;
  padding: 2px;
  background-color: #dcf1f1;
}

.cluesCard .card-body {
  padding: 0;
  overflow: auto;
}

.cluesCard .card-body .smallClueInput {
  padding: 6px;
  height: 1.7rem;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  border-radius: 0;
}
.cluesCard .card-body .clueNumberLabel {
  padding: 3px;
  height: 1.7em;
  width: 2.5em;
  border-style: none;
  border-radius: 0;
}

.cluesCard .card-body .primaryHighlighted {
  background-color: #eef1bf;
}
.cluesCard .card-body .primaryHighlightedDark {
  background-color: #c0c476;
}

.cluesCard .card-body .secondaryHighlighted {
  background-color: #fffed0;
}
.cluesCard .card-body .secondaryHighlightedDark {
  background-color: #ececc0;
}
