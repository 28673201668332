

.constructor-metadata-popover {
  box-shadow: 0 0 5px 2px rgb(169, 69, 199);
  background-color:rgb(250, 244, 253)
}
.constructor-metadata-popover .arrow:after {
  border-bottom-color:rgb(250, 244, 253)
}


