
.playCluesCard {
  margin-bottom: 5px;
  border-width: 1.5px;
  border-color: black;
}

.playCluesCard .card-header {
  text-align: center;
  padding: 3px;
  background-color: #92b4ff;
}

.playCluesCard .card-body {
  padding: 0;
  overflow: auto;
}

.playCluesCard .clueRow {
  padding: 0 4px;
}
.playCluesCard .clueRow:hover {
  padding: 0 4px;
  background-color: #ddf8fa;
}

.playCluesCard .primaryHighlighted {
  background-color: #9bd9f8;
}
.playCluesCard .secondaryHighlighted {
  background-color: #def9fc;
}

.playCluesCard .playClueNumberLabel {
  display: inline-block;
  width: 2rem;
}

.playCluesCard .primaryHighlighted .playClueNumberLabel {
  font-weight: bold;
}
.playCluesCard .secondaryHighlighted .playClueNumberLabel {
  font-weight: bold;
}
