@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* Structure of App */

.block-text {
  font-family: "Courier Prime", monospace;
}
.block-text-bold {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
}

.graph-paper-bkgd {
  background-color: rgb(236, 236, 236);
  background-image: linear-gradient(90deg, rgb(225, 225, 225) 1px, transparent 1px), linear-gradient(rgb(225, 225, 225) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Unified color scheme. */

.purple-1-bkgd, .purple-on-purple {
  background-color: #f6d7ff;
}
.purple-1 {
  color: #f6d7ff;
}
.purple-2-bkgd {
  background-color: #e5a7ef;
}
.purple-2 {
  color: #e5a7ef;
}
.purple-3-bkgd {
  background-color: #B762C1;
}
.purple-3 {
  color: #B762C1;
}
.purple-4-bkgd {
  background-color: #8946A6;
}
.purple-4, .purple-on-purple {
  color: #8946A6;
}

.teal-1-bkgd, .teal-on-teal {
  background-color: #C0FEFC;
}
.teal-1 {
  color: #C0FEFC;
}
.teal-2-bkgd {
  background-color: #99dfea;
}
.teal-2 {
  color: #99dfea;
}
.teal-3-bkgd {
  background-color: #77ACF1;
}
.teal-3 {
  color: #77ACF1;
}
.teal-4-bkgd {
  background-color: #04009A;
}
.teal-4, .teal-on-teal {
  color: #04009A;
}

.grey-0-bkgd {
  background-color: #f1f1f1;
}
.grey-0 {
  color: #f1f1f1;
}
.grey-1-bkgd, .grey-on-grey {
  background-color: #dfdfdf;
}
.grey-1 {
  color: #dfdfdf;
}
.grey-2-bkgd {
  background-color: #969696;
}
.grey-2 {
  color: #969696;
}
.grey-3-bkgd {
  background-color: #4b4b4b;
}
.grey-3 {
  color: #4b4b4b;
}
.grey-4-bkgd {
  background-color: #313131;
}
.grey-4, .grey-on-grey {
  color: #313131;
}

.white-bkgd {
  background-color: white;
}



/* Nav bar */

.App .crossworthyNavbar {
  padding-top: 3px;
  padding-bottom: 3px;
}

.App .crossworthyNavbar .navbar-brand {
  padding: 0;
}
.App .crossworthyNavbar .nav-link {
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 5px;
}




/* Other broad utilities for use anywhere in the app (e.g. animations, spinning) */

.spinning {
  animation: spin infinite 2s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s linear;
}
.fade-invisible {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s linear;
}
.fade-visible-slow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}
.fade-invisible-slow {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.zoomable-subtle {
  transition: transform .2s;
}
.zoomable-subtle:hover {
  transform: scale(1.015);
}
.zoomable-mid {
  transition: transform .2s;
}
.zoomable-mid:hover {
  transform: scale(1.05);
}
.zoomable {
  transition: transform .2s;
}
.zoomable:hover {
  transform: scale(1.1);
}

.not-too-wide {
  max-width: 500px;
}
.not-obscenely-wide {
  max-width: 850px;
}

.clickable {
  cursor: pointer;
}

.balanced-text {
  text-wrap: balance;
}



/* Specific classes */


.keyboard-mid-spacer {
  max-width: 3%;
  visibility: hidden;
}
.keyboard-letter {
  max-width: 9%;
  min-height: 50px;
}
.keyboard-hide {
  min-height: 50px;
}
.keyboard-backspace {
  flex-grow: 1;
  font-size: 26px;
  color: darkred;
  min-height: 50px;
}


